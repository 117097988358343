import React from 'react';
import Panel from '../components/Panel';
import {
  faChevronLeft,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest, HttpResponse} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import LimitTagAutoComplete from '../components/LimitTagAutoComplete';

class SurveyEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      patients: [],
      title: '',
      description: '',
      questions: [],
      selectedIndex: 0,
      questionType: [
        'Text',
        'Rating',
        'Yes or No',
        'Multiple Choice',
        'Integer',
        'Multiple Select',
      ],
      tags: '',
      point: '',
      tagsNew: [],
      currentTag: '',
      tagdata: [],
      currentFruit: '',
      selectedTags: [],
    };

    this.id = props.match?.params?.id;
  }

  componentDidMount() {
    this.loadTagData();
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadSurvey();
    }
  }

  loadTagData() {
    HttpRequest.getallTag()
      .then(res => {
        let data = res.data.data;
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }

  changeFruit = id => {
    this.setState({currentFruit: id});
  };

  loadSurvey() {
    this.setState({isLoading: true});
    HttpRequest.getSurvey(this.id)
      .then(res => {
        let data = res.data.data;
        this.setState({
          isLoading: false,
          title: data.title,
          description: data.description,
          questions: data.questions,
          // selectedTags: data.tags_desc.join(', '),
          tags: data.tags_desc,
          selectedTags: data.tags_desc,
          point: data.point + '',
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load survey data.', 'error');
      });
  }

  save() {
    this.setState({isLoading: true});
    let {title, description, questions, tags, point, selectedTags} = this.state;

    if (
        selectedTags.length === 0 ||
        title === '' ||
        description === '' ||
        point === ''
      ) {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Please fill all inputs', 'error');
        return;
      }

    let data = {
      is_removed: false,
      title: title,
      description: description,
      is_active: true,
      progress_percentage: 0,
      tags: this.state.selectedTags,
      //   tags: ['love'],
      point: parseInt(point),
    };

    HttpRequest.saveSurvey(this.id, data)
      .then(res => {
        let data = res.data.data;
        let surveyId = res.data.data.id;
        //Save Question
        let questionPromises = questions.map(question => {
          let qData = {
            title: question.title,
            type_question: question.type_question,
            survey: surveyId,
            tags: this.state.selectedTags,
          };
          return HttpRequest.saveQuestion(question.id, qData);
        });

        Promise.all(questionPromises)
          .then(res2 => {
            //Update id from database
            res2.map((questionResponse, key) => {
              questions[key].id = questionResponse?.data?.data?.id;
            });

            this.setState({questions});

            //Save Choices
            let choicePromises = [];
            questions.map(question => {
              question.choices.forEach(choice => {
                let cData = {
                  is_removed: false,
                  choice_desc: choice.choice_desc,
                  question: question.id,
                  tags: this.state.selectedTags,
                  //   tags: tags.split(',').map(x => x.trim()),
                };
                choicePromises.push(HttpRequest.saveChoice(choice.id, cData));
              });
            });

            Promise.all(choicePromises)
              .then(res3 => {
                Swal.fire('Information', 'PowerUp saved.', 'success').then(
                  res => {
                    this.setState({
                      isLoading: false,
                      tags: '',
                      point: '',
                      tagsNew: [],
                      currentTag: '',
                      tagdata: [],
                      currentFruit: '',
                      title: '',
                      description: '',
                      selectedTags: [],
                      questions: [],
                    });
                    // window.location = '/survey';
                    this.props.history.push('/survey');
                  },
                );
              })
              .catch(err => {
                this.setState({isLoading: false});
                Swal.fire(
                  'Error',
                  HttpResponse.processError(
                    err.response,
                    'Cannot save choice.',
                  ),
                  'error',
                );
              });
          })
          .catch(err => {
            this.setState({isLoading: false});
            Swal.fire(
              'Error',
              HttpResponse.processError(err.response, 'Cannot save question.'),
              'error',
            );
          });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire(
          'Error',
          HttpResponse.processError(err.response, 'Cannot save PowerUp.'),
          'error',
        );
      });
  }

  addQuestion() {
    let questions = this.state.questions;
    questions.push({
      title: 'Title',
      type_question: 'Multiple Choice',
      choices: [],
      answers: [],
    });
    this.setState({questions, selectedIndex: questions.length - 1});
  }

  handleChange = event => {
    this.setState({currentTag: event.target.value});
  };

  handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Return') {
      this.createTag();
    }
  };

  createTag = () => {
    this.setState(state => ({
      tagsNew: [...state.tagsNew, state.currentTag],
      currentTag: '',
    }));
  };

  handleTagsChange = (event, value) => {
    this.setState({
      selectedTags: value.map(option => option.name),
    });
  };

  render() {
    let selectedQuestion = this.state.questions[this.state.selectedIndex];
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }
    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/survey" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to PowerUps
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
                e.preventDefault();
              this.save();
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <Link
            to="/survey"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">PowerUp name</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  onChange={event => {
                    this.setState({title: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  value={this.state.description}
                  onChange={event => {
                    this.setState({description: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Tags (Separate by Comma)
              </label>
              <div className="col-sm-10">
                <LimitTagAutoComplete
                  tagData={this.state.tagdata}
                  value={this.state.selectedTags}
                  handleTagsChange={this.handleTagsChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Point</label>
              <div className="col-sm-10">
                <input
                type='number'
                  className="form-control"
                  value={this.state.point}
                  onChange={event => {
                    this.setState({point: event.target.value});
                  }}
                />
              </div>
            </div>
          </form>
        </Panel>

        <div className="row">
          <div className="col-4" style={styles.questions}>
            {this.state.questions.map((question, key) => {
              return (
                <Panel
                  style={{
                    border:
                      this.state.selectedIndex == key
                        ? '1px solid var(--color-primary)'
                        : 0,
                  }}
                  key={key}
                  onClick={() => {
                    this.setState({selectedIndex: key});
                  }}>
                  <div style={styles.questionNum}>Question #{key + 1}</div>
                  <div style={styles.questionTitle}>{question.title}</div>
                </Panel>
              );
            })}

            <button
              className="btn btn-primary btn-block"
              style={styles.addQuestionButton}
              onClick={e => {
                e.preventDefault();
                this.addQuestion();
              }}>
              Add question
            </button>
          </div>
          <div className="col-8">
            {selectedQuestion != null && (
              <Panel>
                <div className="section-title">
                  Question #{this.state.selectedIndex + 1}
                </div>
                <form>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Question</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        value={selectedQuestion.title}
                        onChange={event => {
                          let {questions, selectedIndex} = this.state;
                          questions[selectedIndex].title = event.target.value;
                          this.setState({questions});
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Type</label>
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        value={selectedQuestion.type_question}
                        onChange={event => {
                          let {questions, selectedIndex} = this.state;
                          questions[selectedIndex].type_question =
                            event.target.value;
                          this.setState({questions});
                        }}>
                        {this.state.questionType.map((value, key) => {
                          return (
                            <option key={key} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {(selectedQuestion.type_question == 'Multiple Choice' ||
                    selectedQuestion.type_question == 'Multiple Select') && (
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Answer</label>
                      <div className="col-sm-12">
                        {selectedQuestion.choices.map((answer, key) => {
                          return (
                            <div
                              className="input-group"
                              key={key}
                              style={{marginBottom: 10}}>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  {String.fromCharCode(key + 65).toLowerCase()}.
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={answer.choice_desc}
                                placeholder="Add Answer"
                                onChange={event => {
                                  let {questions, selectedIndex} = this.state;
                                  let answers =
                                    questions[selectedIndex].choices;
                                  answers[key].choice_desc = event.target.value;
                                  questions[selectedIndex].choices = answers;
                                  this.setState({questions});
                                }}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => {
                                    let {questions, selectedIndex} = this.state;
                                    let answers =
                                      questions[selectedIndex].choices;
                                    answers.splice(key, 1);
                                    questions[selectedIndex].choices = answers;
                                    this.setState({questions});
                                  }}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </div>
                            </div>
                          );
                        })}

                        <button
                          className="btn btn-primary btn-block"
                          onClick={e => {
                            e.preventDefault();
                            let {questions, selectedIndex} = this.state;
                            let answers = questions[selectedIndex].choices;
                            answers.push({
                              choice_desc: '',
                            });
                            questions[selectedIndex].choices = answers;
                            this.setState({questions});
                          }}>
                          Add answer
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </Panel>
            )}
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
  questions: {
    overflowY: 'auto',
    height: '60vh',
  },
  questionNum: {
    // fontWeight: 'bold',
    fontSize: 18,
  },
  questionTitle: {
    // fontWeight: 'bold'
  },
  addQuestionButton: {
    height: 50,
    borderRadius: 10,
    background: '#E8EDF0',
    color: '#2979bd',
    border: 0,
    fontWeight: 'bold',
    position: 'sticky',
    bottom: 0,
  },
};

export default withRouter(SurveyEdit);
