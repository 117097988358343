import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
  faCommentAlt,
  faEdit,
  faEyeSlash,
  faPaste,
  faPlus,
  faSearch,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';

class Resource extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      resources: [],
      selectedResource: null,
      searchText: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadResource();
    }
  }

  loadResource() {
    this.setState({isLoading: true});

    HttpRequest.resourceList()
      .then(res => {
        let data = res.data.results;
        data.sort((a, b) => new Date(b.id) - new Date(a.id));
        this.setState({
          isLoading: false,
          resources: data,
          filteredItems:data
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load resource data.', 'error');
      });
  }

  handleDelete = (resourceID) => {
    Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this resource?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ isLoading: true });
        HttpRequest.deleteResource(resourceID)
          .then(() => {
            const updatedResources = this.state.resources.filter(
              (resource) => resource.id !== resourceID
            );
  
            this.setState({
              isLoading: false,
              resources: updatedResources,
            });
  
            Swal.fire('Success', 'Resource deleted successfully.', 'success');
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            Swal.fire('Error', 'Cannot delete resource.', 'error');
          });
      }
    });
  };
  
    
    applyFilters() {
        const {searchText, resources} = this.state;

        let filteredResources = resources;

        if (searchText) {
            filteredResources = filteredResources.filter(
              item =>
                item.title &&
                item.title.toLowerCase().includes(searchText.toLowerCase()),
            );
          }

          this.setState({selectedResource: filteredResources})
    }

  render() {
    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/resource/create" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faPlus} /> Add resource
          </Link>
        </div>
        <Panel>
          {this.state.isLoading && <ActivityIndicator />}
          {!this.state.isLoading && (
            <DataGrid
              filters={[
                {
                  type: 'textinput',
                  label: 'Search resource',
                  icon: faSearch,
                  onChange: text => {
                    this.setState({searchText: text}, this.applyFilters)
                  },
                  className: 'col-sm-12 col-md-12',
                },
              ]}
              columns={[
                {name: 'title', label: 'Resource Name'},
                {
                  label: 'Tags',
                  renderItem: item => {
                    return item.tags?.map((tag, key) => {
                      return (
                        <span
                          className="badge badge-success"
                          key={key}
                          style={{marginRight: 5}}>
                          {tag}
                        </span>
                      );
                    });
                  },
                },
                {
                  label: 'Date posted',
                  renderItem: item => {
                    return moment(item.created_at).format('MMM DD, YYYY');
                  },
                },
                {
                  label: 'Type',
                  renderItem: item => {
                    return 'Article';
                  },
                },
                {
                  label: 'Controls',
                  renderItem: item => {
                    return (
                      <>
                        <Link
                          to={'/resource/edit/' + item.id}
                          data-toggle="tooltip" data-placement="bottom" title="Edit Resource"
                          style={styles.control}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                        <span
                          style={styles.deleteBtn}
                          data-toggle="tooltip" data-placement="bottom" title="Delete Resource"
                          onClick={()=>this.handleDelete(item.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </>
                    );
                  },
                },
              ]}
              data={
                    this.state.selectedResource
                      ? this.state.selectedResource
                      : this.state.resources || []
              }
            />
          )}
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
  deleteBtn:{
      marginLeft: 8,
      cursor:"pointer"
  }
};

export default Resource;
