import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest, HttpResponse} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import RichTextEditor from '../components/RichTextEditor';
import Swal from 'sweetalert2';
import StringHelper from '../utils/StringHelper';
import LimitTagAutoComplete from '../components/LimitTagAutoComplete';
import { Modal, Paper } from '@mui/material';
import {TextField} from '@mui/material';
import styled from '@emotion/styled';
import AddIcon from '@material-ui/icons/Add';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};

class ResourceEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      title: '',
      description: '',
      selectedTags: [],
      tagdata: [],
      image: null,
      isImagePreviewOpen: false,
      isImageUploaded: false,
    };

    this.id = props.match?.params?.id;
  }

  componentDidMount() {
    this.loadTagData();
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadResource();
    }
  }

  loadResource() {
    this.setState({isLoading: true});

    HttpRequest.getResource(this.id)
      .then(res => {
        let data = res.data.data;
        console.log("🚀 ~ ResourceEdit ~ loadResource ~ data:", data)

        this.setState({
          isLoading: false,
          title: data?.title,
          description: data?.description,
          tags: data.tags,
          selectedTags: data.tags,
          image: data.file
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load resource data.', 'error');
      });
  }

  loadTagData() {
    HttpRequest.getallTag()
      .then(res => {
        let data = res.data.data;
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }
  handleTagsChange = (event, value) => {
    this.setState({
      selectedTags: value.map(option => option.name),
    });
  };

  save() {
    let user = store.getState().loginReducer.user;

    let {title, description, selectedTags} = this.state;

    if (!title || !description || selectedTags.length === 0) {
      Swal.fire('Error', 'Please fill in all fields.', 'error');
      return;
    }
    this.setState({isLoading: true});

    // let data = {
    //   is_removed: false,
    //   title: this.state.title,
    //   description: this.state.description,
    //   url_link:
    //     'https://shrill-silence-24189-staging.herokuapp.com/' +
    //     StringHelper.slugify(this.state.title) +
    //     '/' +
    //     Math.floor(Math.random() * 10000),
    //   author: user.user.id,
    //   shared_permission: [1, 2, 3, 4, 5],
    //   tags: this.state.selectedTags,
    // };
    const formdata = new FormData();
    formdata.append('title', this.state.title);
    formdata.append('is_removed', false);
    formdata.append('description', this.state.description);
    formdata.append('url_link', 
    'https://shrill-silence-24189-staging.herokuapp.com/' +
    StringHelper.slugify(this.state.title) +
    '/' +
    Math.floor(Math.random() * 10000));
    formdata.append('author', user.user.id);
    formdata.append('shared_permission', 1);
    formdata.append('tags', JSON.stringify(this.state.selectedTags));
    if (!this.id) {
      formdata.append('file', this.state.image);
    }
    let body = formdata;


    HttpRequest.saveResource(this.id, body)
      .then(res => {
        let data = res.data.data;

        this.setState({isLoading: false});
        Swal.fire('Information', 'Resource saved.', 'success').then(res => {
          this.props.history.push('/resource');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire(
          'Error',
          HttpResponse.processError(err.response, 'Cannot save resource data.'),
          'error',
        );
      });
  }
  
  handleImageUpload = e => {
    this.setState({image: e.target.files[0]});
    this.setState({isImageUploaded: true});
  };

  handleImagePreviewOpen = () => {
    this.setState({
      isImagePreviewOpen: true,
    });
  };

  handleImagePreviewClose = () => {
    this.setState({
      isImagePreviewOpen: false,
    });
  };


  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/resource" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to resource
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
              e.preventDefault();
              this.save();
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <a
            onClick={this.handleImagePreviewOpen}
            className="btn btn-primary btn-round"
            style={{marginLeft: 10, color: 'white'}}>
            Preview
          </a>
          <Link
            to="/resource"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Resource Title</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  onChange={event => {
                    this.setState({title: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Tags</label>
              <div className="col-sm-10">
                <LimitTagAutoComplete
                  tagData={this.state.tagdata}
                  value={this.state.tags}
                  handleTagsChange={this.handleTagsChange}
                />
              </div>
            </div>
            <RichTextEditor
              defaultEditorState={this.state.description}
              onEditorStateChange={description => {
                this.setState({description: description});
              }}
            />
            <div>
              {this.id ? (
                ' '
              ) : (
                <>
                  <StyledContainer>
                    <p style={{whiteSpace: 'nowrap', color: 'black'}}>
                      Upload Image
                    </p>
                    <div
                      style={{
                        margin: '1% 0 2% 10%',
                        color: 'green',
                        position: 'absolute',
                      }}>
                      {this.state.isImageUploaded && (
                        <div>Image is uploaded</div>
                      )}
                    </div>
                    <UploadSection htmlFor="file">
                      <LabelStyle htmlFor="file">Browse</LabelStyle>
                    </UploadSection>
                    <TextField
                      id="file"
                      type="file"
                      variant="outlined"
                      onChange={this.handleImageUpload}
                      sx={{width: '100%', display: 'none'}}
                    />
                  </StyledContainer>
                  <label className="col-sm-1 col-form-label"></label>
                  <LabelStyle htmlFor="file" style={{margin: '1% 0 0 2%'}}>
                    <AddIcon style={{fontSize: '16px'}} /> Add media
                  </LabelStyle>
                </>
              )}
            </div>
          </form>
        </Panel>
        <Modal
          open={this.state.isImagePreviewOpen}
          onClose={this.handleImagePreviewClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description">
          <div style={style}>
            <Paper elevation={3}>
              {this.state.image ? (
                typeof this.state.image === 'object' ? (
                  <img
                    src={
                      URL.createObjectURL(this.state.image) || this.state.image
                    }
                    alt="Preview"
                    style={{height: 300, width: 400}}
                  />
                ) : (
                  <img
                    src={this.state.image}
                    alt="Preview"
                    style={{height: 300, width: 400}}
                  />
                )
              ) : (
                <img
                  src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  alt="Preview"
                  style={{height: 300, width: 400}}
                />
              )}
            </Paper>
          </div>
        </Modal>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(ResourceEdit);

const StyledContainer = styled.div`
  padding: 10px;
  margin-top: 15px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  gap: 1%;
  align-items: center;
`;
const UploadSection = styled.label`
  display: flex;
  justify-content: flex-end;
  outline: 1px solid grey;
  height: 40px;
  padding: 7px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
`;
const LabelStyle = styled.label`
  color: #0e82c8;
  font-size: 14px;
  cursor: pointer;
`;

