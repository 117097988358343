
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import StringHelper from '../utils/StringHelper';
import CircleImage from './CircleImage';
import Panel from './Panel';
import Mime from 'mime-types';
import {
  faAlignJustify,
  faCross,
  faEllipsisH,
  faImage,
  faPlayCircle,
  faThumbsUp,
  faTimes,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {HttpRequest} from '../utils/http';
import {store} from '../store';
import {
  MILESTONE_NEGATIVE,
  MILESTONE_NEUTRAL,
  MILESTONE_POSITIVE,
} from '../store/constants';
import moment from 'moment';
import CareImage from '../assets/icon-care.png';
import HappyImage from '../assets/icon-happy.png';
import SadImage from '../assets/icon-sad.png';
import NeutralImage from '../assets/icon-neutral.png';
import IconImage from "../assets/logo192.png"

export default class MilestoneBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      name: '',
      last_name: '',
      image: null,
    };
  }

  vote() {
    let {milestone} = this.props;
    let user = store.getState().loginReducer.user;

    let data = {
      milestone: milestone.id,
      user: user.user.id,
      vote: 1,
    };

    HttpRequest.voteMilestone(data)
      .then(res => {
        Swal.fire('Success', 'Successfully vote this milestone.', 'success');
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot vote this milestone.', 'error');
      });
  }

  care() {
    let {milestone} = this.props;

    let data = {
      milestone: milestone.id,
    };

    HttpRequest.careMilestone(data)
      .then(res => {
        Swal.fire('Success', 'Successfully care this milestone.', 'success');

        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot care this milestone.', 'error');
      });
  }

  renderCareButton() {
    let {milestone} = this.props;

    return (
      <>
        <a style={styles.voteButton} onClick={() => {
                    this.vote();
                }}>
                    <FontAwesomeIcon icon={faThumbsUp} size={40} style={{ fontSize: 20 }} />
                    Vote
                </a>

        <a
          style={styles.careButton}
          onClick={() => {
            this.care();
          }}>
          <img src={CareImage} style={{height: 20}} />
          Care
        </a>
      </>
    );
  }

   renderFace() {
    let {milestone} = this.props;
    let face = milestone.status;
    if (face == 1 || face == 2 || face == 3) {
      return (
        <>
          {face == MILESTONE_NEUTRAL && (
            <CircleImage src={NeutralImage} size={25} />
          )}
          {face == MILESTONE_POSITIVE && (
            <CircleImage src={HappyImage} size={25} />
          )}
          {face == MILESTONE_NEGATIVE && (
            <CircleImage src={SadImage} size={25} />
          )}
        </>
      );
    }

    return <CircleImage src={face} size={40} />;
  }

  loadPatientDetail() {
    let {milestone} = this.props;
    this.setState({isLoadingProfile: true});

    HttpRequest.getPatient(milestone?.patient)
      .then(res => {
        let data = res.data.data;
        this.setState({
          isLoadingProfile: false,
          name: data.user.name,
          last_name: data.user.last_name,
          image: data.image,
        });
      })
      .catch(err => {
        this.setState({isLoadingProfile: false});
        Swal.fire('Error', 'Cannot load patient data.', 'error');
      });
  }

  renderDialog() {
    let {milestone} = this.props;

    let icon = null;
    let mediaLength = null;

    let type = 'text';
    let file = milestone.file;
    
    if (StringHelper.isValidURL(file)) {
      file = StringHelper.clearUrl(file);
      let mimeFile =
        Mime.lookup(StringHelper.pathBaseName(file)) || 'image/jpeg';
      type = mimeFile.substr(0, 5);
    }

    return (
      <>
        {this.state.showDialog && (
          <>
            <div
              style={styles.overlayDown}
              onClick={() => {
                this.setState({showDialog: false});
              }}
            />

            <div style={styles.overlayTop}>
              {type == 'text' && (
                <div
                  className="flex align-center justify-center"
                  style={{display: 'flex', height: '100vh'}}>
                  <div style={styles.box}>
                    <div style={styles.boxContentText}>
                      <div style={{fontWeight: 'bold'}}>{milestone.title}</div>
                      {/* {milestone.description} */}
                    </div>

                    <div style={styles.boxContentInfo}>
                      {this.renderFace()}

                      <FontAwesomeIcon
                        icon={faAlignJustify}
                        style={{fontSize: 18, marginLeft: 10}}
                      />

                      <div style={{flex: 1}}></div>

                      {/* <FontAwesomeIcon
                        icon={faEllipsisH}
                        style={{fontSize: 18}}
                      /> */}
                    </div>

                    <div style={styles.boxContentUser}>
                      <CircleImage
                        src={milestone.patient.image || IconImage}
                        size={40}
                        style={{marginRight: 10}}
                      />

                      <div style={{flex: 1}}>
                        <div>
                          {milestone.patient?.name}{' '}
                          {milestone.patient?.last_name}
                        </div>
                        <div>
                          {moment(milestone.created).format('MMM DD, YYYY')}
                        </div>
                      </div>
                      {this.renderCareButton()}
                    </div>

                    <a
                      style={styles.closeButton}
                      onClick={() => {
                        this.setState({showDialog: false});
                      }}>
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </div>
                </div>
              )}

              {type == 'video' && (
                <div
                  className="flex align-center justify-center"
                  style={{display: 'flex', height: '100vh'}}>
                  <div style={styles.box}>
                    <div
                      style={{
                        background: '#000',
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                      }}>
                      <video
                        controls={true}
                        style={{
                          width: 500,
                          height: 300,
                        }}>
                        <source src={file} type="video/mp4" />
                      </video>
                    </div>

                    <div style={styles.boxContentTitle}>
                      <div style={{fontWeight: 'bold'}}>{milestone.title}</div>
                    </div>

                    <div style={styles.boxContentInfo}>
                      {this.renderFace()}

                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        style={{fontSize: 18, marginLeft: 10}}
                      />

                      <div style={{flex: 1}}></div>

                      {/* <FontAwesomeIcon
                        icon={faEllipsisH}
                        style={{fontSize: 18}}
                      /> */}
                    </div>

                    <div style={styles.boxContentUser}>
                      <CircleImage
                        src={milestone.patient.image}
                        size={40}
                        style={{marginRight: 10}}
                      />

                      <div style={{flex: 1}}>
                        <div>
                          {milestone.patient?.name}{' '}
                          {milestone.patient?.last_name}
                        </div>
                        <div>
                          {moment(milestone.created).format('MMM DD, YYYY')}
                        </div>
                      </div>
                      {this.renderCareButton()}
                    </div>

                    <a
                      style={styles.closeButton}
                      onClick={() => {
                        this.setState({showDialog: false});
                      }}>
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </div>
                </div>
              )}
              {type == 'audio' && (
                <div
                  className="flex align-center justify-center"
                  style={{display: 'flex', height: '100vh'}}>
                  <div style={styles.box}>
                    <div
                      style={{
                        background: '#000',
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                      }}>
                      <audio style={{width: 500, height: 100}} controls>
                        <source src={file} type="audio/ogg" />
                      </audio>
                    </div>

                    <div style={styles.boxContentTitle}>
                      <div style={{fontWeight: 'bold'}}>{milestone.title}</div>
                    </div>

                    <div style={styles.boxContentInfo}>
                      {this.renderFace()}

                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        style={{fontSize: 18, marginLeft: 10}}
                      />

                      <div style={{flex: 1}}></div>

                      {/* <FontAwesomeIcon
                        icon={faEllipsisH}
                        style={{fontSize: 18}}
                      /> */}
                    </div>

                    <div style={styles.boxContentUser}>
                      <CircleImage
                        src={milestone.patient.image}
                        size={40}
                        style={{marginRight: 10}}
                      />

                      <div style={{flex: 1}}>
                        <div>
                          {milestone?.patient?.first_name}{' '}
                          {milestone?.patient?.last_name}
                        </div>
                        <div>
                          {moment(milestone.created).format('MMM DD, YYYY')}
                        </div>
                      </div>
                      {this.renderCareButton()}
                    </div>

                    <a
                      style={styles.closeButton}
                      onClick={() => {
                        this.setState({showDialog: false});
                      }}>
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </div>
                </div>
              )}

              {type == 'image' && (
                <div
                  className="flex align-center justify-center"
                  style={{display: 'flex', height: '100vh'}}>
                  <div style={styles.box}>
                    <div
                      style={{
                        background: '#000',
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                      }}>
                      <img
                        src={file}
                        style={{width: 500, height: 300, objectFit: 'contain'}}
                      />
                    </div>

                    <div style={styles.boxContentTitle}>
                      <div style={{fontWeight: 'bold'}}>{milestone.title}</div>
                    </div>

                    <div style={styles.boxContentInfo}>
                      {this.renderFace()}

                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        style={{fontSize: 18, marginLeft: 10}}
                      />

                      <div style={{flex: 1}}></div>

                      {/* <FontAwesomeIcon
                        icon={faEllipsisH}
                        style={{fontSize: 18}}
                      /> */}
                    </div>

                    <div style={styles.boxContentUser}>
                      <CircleImage
                        src={milestone.patient.image}
                        size={40}
                        style={{marginRight: 10}}
                      />

                      <div style={{flex: 1}}>
                        <div>
                          {milestone.patient?.name}{' '}
                          {milestone.patient?.last_name}
                        </div>
                        <div>
                          {moment(milestone.created).format('MMM DD, YYYY')}
                        </div>
                      </div>
                      {this.renderCareButton()}
                    </div>

                    <a
                      style={styles.closeButton}
                      onClick={() => {
                        this.setState({showDialog: false});
                      }}>
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  render() {
    let {milestone} = this.props;

    let icon = null;
    let mediaLength = null;

    let type = 'text';
    let file = milestone.file;
    if (StringHelper.isValidURL(file)) {
      file = StringHelper.clearUrl(file);
      let mimeFile =
        Mime.lookup(StringHelper.pathBaseName(file)) || 'image/jpeg';
      type = mimeFile.substr(0, 5);
    }

    if (this.props.renderDisplay != null) {
      return (
        <>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.setState({showDialog: true});
            }}>
            {this.props.renderDisplay()}
          </a>
          {this.renderDialog()}
        </>
      );
    }

    return (
      <Panel style={{position: 'relative'}}>
        {type == 'image' && (
          <div
            style={{background: 'url(' + file + ')', ...styles.bgMilestone}}
          />
        )}
        {type == 'text' && (
          <div
            style={{background: 'url(' + IconImage + ')', ...styles.bgMilestone}}
          />
        )}
        {type == 'video' && (
          <video
            style={{
              width: '100%',
              height: 162,
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0.3,
            }}>
            <source src={file} type="video/mp4" />
          </video>
        )}
        <div style={{background: 'white', ...styles.bgMilestone}}>
          {/* {type == 'text' && (
            <>
              {milestone.description} {milestone.description}{' '}
              {milestone.description} {milestone.description}
            </>
          )} */}
        </div>
        <div
          style={styles.milestone}
          onClick={() => {
            this.setState({showDialog: true}, () => {
              //this.loadPatientDetail();
            });
          }}>
          <div className="flex-row align-center" style={styles.type}>
            {type == 'image' && (
              <FontAwesomeIcon icon={faImage} style={{marginRight: 5}} />
            )}
            {type == 'video' && (
              <FontAwesomeIcon icon={faPlayCircle} style={{marginRight: 5}} />
            )}
            {type == 'audio' && (
              <FontAwesomeIcon icon={faVolumeUp} style={{marginRight: 5}} />
            )}
            {mediaLength}
          </div>
          <CircleImage
            src={milestone.patient.image}
            size={40}
            style={{margin: '7px 0 12px'}}
          />
          <div style={styles.name}>{milestone.title}</div>
        </div>

        {this.renderDialog()}
      </Panel>
    );
  }
}

const styles = {
  milestone: {
    color: '#D6D6D6',
    height: 130,
    position: 'relative',
    cursor: 'pointer',
  },
  bgMilestone: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.3,
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    borderRadius: 10,

    overflow: 'hidden',
    padding: '10px 13px',
  },
  type: {
    color: 'var(--color-text)',
    height: 25,
  },
  name: {
    color: 'var(--color-text)',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: '19px',
  },
  overlayDown: {
    zIndex: 1030,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.3)',
    cursor: 'pointer'
  },
  overlayTop: {
    zIndex: 1040,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.3)',
  },
  showBigText: {
    position: 'relative',
    fontSize: 25,
    color: 'var(--color-text)',
    background: '#fff',
    padding: 20,
    borderRadius: 10,
    width: 500,
  },
  box: {
    position: 'relative',
    fontSize: 12,
    color: 'var(--color-text)',
    background: '#fff',
    borderRadius: 10,
    width: 500,
  },
  boxContentText: {
    fontSize: 12,
    padding: 16,
    color: 'var(--color-text)',
  },
  boxContentTitle: {
    fontSize: 12,
    padding: '16px 16px 0px 16px',
    color: 'var(--color-text)',
  },
  boxContentInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
    borderBottom: '1px solid #e6e6e6',
  },
  boxContentUser: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
  },
  careButton: {
    // background: "#fff",
    // position: "absolute",
    // top: 38,
    // right: 36,
    //padding: 10,
    // borderRadius: 10,
    fontSize: 12,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  voteButton: {
    background: '#fff',
    position: 'absolute',
    top: 38,
    right: 100,
    padding: 10,
    borderRadius: 10,
    fontSize: 12,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: -10,
    right: -10,

    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    border: '1px solid var(--color-text)',
  },
}