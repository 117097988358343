import {faCommentAlt, faThumbtack} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '@utils/http';
import moment from 'moment';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import TextInput from './TextInput';

export default function MessagingNote(props) {
  const userId = props.userId;
  const user = useSelector(state => state.loginReducer.user);
  const patients = useSelector(state => state.profiles);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');

  useEffect(() => {
    loadNotes();
  }, [user]);

  const loadNotes = useCallback(() => {
    setIsLoading(true);

    HttpRequest.noteList(userId)
      .then(res => {
        let data = res.data.results;

        setNotes(data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        Swal.fire('Error', 'Cannot load note data. mj,vk', 'error');
      });
  }, [userId]);

  const updateNote = useCallback(() => {
    // Check if the note is null or empty before sending the request
    if (!note.trim()) {
      Swal.fire('Error', 'Please, Enter something in Note!!!', 'error');
      return; // Exit the function without sending the request
    }
  
    let data = {
      is_removed: false,
      notes: note,
      is_pinned: false,
      seen: false,
      provider: user.user.id,
      patient: userId,
    };
    HttpRequest.saveNote(null, data)
      .then(res => {
        Swal.fire('Information', 'Note successfully updated.', 'success');
        setNote('');
        loadNotes();
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot save note.', 'error');
      });
  }, [userId, note, user]);
  

  const patientName = useMemo(() => {
    let patient = patients[userId + ''];
    if (patient) {
      return patient.user.name + ' ' + patient.user.last_name;
    }

    return 'Guest';
  }, [userId, patients]);

  return (
    <>
      <div className="section-title">
        Notes for <b>{props.user ? props.user?.user?.name + " " + props.user?.user?.last_name  : patientName}</b>
      </div>

      {notes.map((note, key) => {
        return (
          <div style={styles.notes} key={key}>
            {note.is_pinned && <FontAwesomeIcon icon={faThumbtack} />}
            Created: {moment(note.created).format('MMM DD, YYYY, HH:mm')}
            <div style={styles.boxNote}>
              <FontAwesomeIcon icon={faCommentAlt} /> {note.notes}
            </div>
          </div>
        );
      })}

      <div style={styles.notes}>
        Add Note
        <TextInput
          value={note}
          onChange={event => {
            setNote(event.target.value);
          }}
        />
        <button
          className="btn btn-primary"
          onClick={() => {
            updateNote();
          }}>
          Save
        </button>
      </div>
    </>
  );
}

const styles = {
  notes: {
    fontSize: 12,
    color: '#565656',
  },
  boxNote: {
    marginTop: 10,
    marginBottom: 20,
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 10,
    fontSize: 14,
    color: 'var(--color-text)',
    background: '#ececec',
  },
};
