import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, Link, useLocation, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faSearch,
    faBars,
    faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import './assets/dashboard.css';
import Login from './screen/Login';
import { setProfile, setUser } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { store } from './store';
import LocalStorage from 'local-storage';
import route from './config/route';
import { ROLE_ADMIN, ROLE_AGENT, ROLE_PROVIDER } from './store/constants';
import { HttpRequest } from './utils/http';
import SearchBar from './components/SearchBar';
import Logout from './screen/Logout';
import imageUtil from './utils/imageUtil';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './components/mui/theme';
import ProfilePic from "./assets/logo192.png"
import Logo from './assets/ygbr-logo.png';
import { useMemo } from 'react';
import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { appConfig } from './config/app';
import Swal from 'sweetalert2';

export default function App(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.loginReducer.user);
    const profile = useSelector(state => state.profile);
    let profileName = ""
    if(profile === null) {
        profileName = JSON.parse(localStorage.getItem('user'))
    }

    const pubnub = useMemo(() => {
        if (user) {
            return new Pubnub({
                publishKey: appConfig.pubPublishKey,
                subscribeKey: appConfig.pubSubscribeKey,
                uuid: user.user.id + '',
            });
        }
    
        return null;
    }, [user]);
    

    const history = useHistory();
    const locationInfo = useLocation();
    const [switchRoutes, setSwitchRoutes] = useState([]);
    const [breadCrumbs, setBreadCrumbs] = useState([]);
    const [locations, setLocations] = useState([]);
    const [activePage, setActivePage] = useState('regular');
    const [image, setImage] = useState(null);

    useEffect(() => {
        setSwitchRoutes(route.getSwitchRoute());
        if (user) {
            setLocations(route.getSidebarMenu(user?.user?.role));

            HttpRequest.getProvider(user.user.id)
                .then(res => {
                    let data = res.data.data;
                    console.log("🚀 ~ useEffect ~ data:", data)
                    setImage(data?.image)
                    dispatch(setProfile(data));
                })
                .catch(err => { });
        }
    }, [user]);
    function useRoleAccess(requiredRoles) {
        const user = useSelector(state => state.loginReducer.user);
        return user && requiredRoles.includes(user.user.role);
    }


    function ProtectedRoute({ route, component: Component, ...rest }) {
        const hasAccess = useRoleAccess(route.roles);
        return hasAccess ? (
    <Route {...rest} render={props => <Component {...props} />} />
  ) : (
    <Redirect to="/" />
  );
    }
    useEffect(() => {
        let pathName = locationInfo.pathname;
        
        setBreadCrumbs(route.getBreadCrumb(pathName));
        if (pathName == '/login') {
            setActivePage('login');
        } else if (pathName == '/logout') {
            setActivePage('logout');
        } else {
            setActivePage('regular');
            if (!isLoggedIn()) {
                history.push('/login');
            }
        }
    }, [locationInfo.pathname]);

    const isLoggedIn = useCallback(() => {
        let userLS = LocalStorage.get('user');
        if (userLS != null) {
            let userLsObj = JSON.parse(userLS);
            dispatch(setUser(userLsObj));
            return true;
        }
        return false;
    }, []);

    const greetings = useCallback(() => {
        let today = new Date();
        let curHr = today.getHours();
        let greeting = 'Good Day';

        if (curHr > 4 && curHr < 12) {
            greeting = 'Good Morning';
        } else if (curHr >= 12 && curHr < 18) {
            greeting = 'Good Afternoon';
        } else {
            greeting = 'Good Evening';
        }
        return greeting;
    }, []);

    const isPageActive = useCallback((link, pathName) => {
        if (link == '/') {
            if (pathName == link) {
                return true;
            }
        } else {
            if (pathName.startsWith(link)) {
                return true;
            }
        }
        return false;
    }, []);

    if (activePage == 'login') {
        return <Login />;
    } else if (activePage == 'logout') {
        return <Logout />;
    } else {
        if (user) {
            return (
                <PubNubProvider client={pubnub}>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light d-md-none">
                        <Link to="/" className="navbar-brand">
                            <img src={ProfilePic} style={{ width: 30 , borderRadius: "100%"}} />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={faBars} />
                        </button>

                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                {locations.map((location, key) => {
                                    if (isPageActive(location.link, locationInfo.pathname)) {
                                        //setActivePage(location.label);
                                    }
                                    return (
                                        <li
                                            className={
                                                'nav-item ' +
                                                (isPageActive(location.link, locationInfo.pathname)
                                                    ? 'active'
                                                    : '')
                                            }
                                            key={key}>
                                            <Link to={location.link} className={'nav-link'}>
                                                {location.label}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </nav>

                    <div className="container-fluid">
                        <div className="row">
                            <nav className="col-md-2 d-none d-md-block sidebar ">
                                <div className="sidebar-sticky thin-scrollbar">
                                    <div className="logo-wrapper">
                                        <img src={Logo} className="logo" />
                                    </div>
                                    <div className="doctor-wrapper">
                                        <img
                                            // src={user.user.image || ProfilePic}
                                            src={imageUtil.getImage(image)}
                                            className="doctor-icon"
                                        />
                                        <a
                                            href="#"
                                            className="btn-signout flex-column align-center justify-center"
                                            onClick={e => {
                                                e.preventDefault();
                                                Swal.fire({
                                                    title: 'Are you sure you want to logout?',
                                                    icon: 'question',
                                                    showCancelButton: true,
                                                    confirmButtonText: 'Yes, logout',
                                                    cancelButtonText: 'Cancel',
                                                }).then(result => {
                                                    if (result.isConfirmed) {
                                                        dispatch(setUser(null));
                                                        LocalStorage.clear();
                                                        window.location = '/';
                                                    }
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </a>
                                    </div>

                                    <div className="doctor-name">
                                        {greetings()}
                                        <br />
                                        <b>
                                            {user?.user.name}  {user?.user.last_name}
                                        </b>
                                        <br />
                                        <span className="badge badge-success">
                                            {user?.user?.role == ROLE_PROVIDER && 'Provider'}
                                            {user?.user?.role == ROLE_AGENT && 'Agent'}
                                            {user?.user?.role == ROLE_ADMIN && 'Admin'}
                                        </span>
                                    </div>

                                    <ul className="nav flex-column">
                                        {locations.map((location, key) => {
                                            if (isPageActive(location.link, locationInfo.pathname)) {
                                                //setActivePage(location.label);
                                            }
                                            return (
                                                <li className="nav-item" key={key}>
                                                    <Link
                                                        to={location.link}
                                                        className={
                                                            'nav-link ' +
                                                            (isPageActive(
                                                                location.link,
                                                                locationInfo.pathname,
                                                            )
                                                                ? 'active'
                                                                : '')
                                                        }>
                                                        <span className="icon-wrapper">
                                                            <FontAwesomeIcon icon={location.icon} />
                                                        </span>
                                                        {location.label}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </nav>

                            <main role="main" className="col-md-9 ml-sm-auto col-lg-10">
                                <SearchBar />

                                <div className="content pt-3 px-2">
                                    {/* Show on desktop */}
                                    <div id="header-text" className="d-none d-sm-block">
                                        Yellowstone Boys and Girls Ranch <Link to="/"><b>Dashboard</b></Link>
                                        {breadCrumbs.map((breadCrumb, key) => {
                                            if (key != breadCrumbs.length - 1) {
                                                return (
                                                    <Link
                                                        to={breadCrumb.link}
                                                        style={{ color: '#0077bd', fontWeight: 'bold' }}
                                                        key={key}>
                                                        <FontAwesomeIcon
                                                            icon={faChevronRight}
                                                            style={{ margin: '0 10px', fontSize: 10 }}
                                                        />
                                                        {breadCrumb.label}
                                                    </Link>
                                                );
                                            } else {
                                                return (
                                                    <span style={{ color: 'var(--color-text)' }} key={key}>
                                                        <FontAwesomeIcon
                                                            icon={faChevronRight}
                                                            style={{ margin: '0 10px', fontSize: 10 }}
                                                        />
                                                        {breadCrumb.label}
                                                    </span>
                                                );
                                            }
                                        })}
                                    </div>
                                    {/* Show on mobile */}
                                    <div id="header-text" className="d-block d-sm-none">
                                        <b>Dashboard</b>
                                        <span style={{ color: 'var(--color-text)' }}>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                style={{ margin: '0 10px', fontSize: 10 }}
                                            />
                                            {activePage}
                                        </span>
                                    </div>
                                    <ThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <Switch>
                                            {switchRoutes.map((route, key) => {
                                                return (
                                                    <ProtectedRoute
                                                        key={key}
                                                        path={route.path}
                                                        route={route} // Pass the entire route item
                                                        children={route.children}
                                                    />
                                                );
                                            })}
                                        </Switch>
                                    </ThemeProvider>
                                </div>
                            </main>
                        </div>
                    </div>
                </PubNubProvider>
            );
        } else {
            return <div className="container">Not Found</div>;
        }
    }
}
