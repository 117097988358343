import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest, HttpResponse} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import RichTextEditor from '../components/RichTextEditor';
import Swal from 'sweetalert2';
import LimitTagAutoComplete from '@components/LimitTagAutoComplete';
import ReactPatientSelect from '@components/ReactPatientSelect';

class PlanEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      planTypes: [
        {id: 'Discharge', label: 'Discharge'},
        {id: 'Aftercare', label: 'Aftercare'},
        {id: 'Crisis', label: 'Crisis'},
        {id: 'Archived', label: 'Archived'},
      ],
      title: '',
      description: '',
      type: '',
      link: '',
      assign: null,
      media: '',
      tagdata: [],
      patients: [],
      selectedPatients: [],
      loadedPatients: [],
      selectedTags: [],
      currentPage: 1,
      next: null,
      previous: null,
      totalPages: 1,
    };

    this.id = props.match?.params?.id;
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    
    if (user && this.id != null) {
        this.loadPlan();
      }
    this.loadTagData();
    this.loadProfile();
  }

  loadPlan() {
    this.setState({isLoading: true});

    HttpRequest.getPlan(this.id)
      .then(res => {
        let data = res.data.data;
        console.log("Loaded Plan", data)
        const loadedPatients = this.state.patients.filter(patient => data?.patients?.includes(parseInt(patient.user.id)));
        console.log("Patients", loadedPatients)
        console.log("All Patients", this.state.patients)
        this.setState({
          isLoading: false,
          title: data.title,
          description: data.description,
          type: data.type,
          selectedTags: data.tags,
          loadedPatients: loadedPatients
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load plan data.', 'error');
      });
  }
  loadProfile() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    HttpRequest.showPatientList(this.state.currentPage)

      .then(res => {
        let next = res.data.next;
        const nextPageNumber = next ? next.split('page=')[1] : null;
        const count = res.data.count;
        const roundedPage = Math.ceil(count / 20);
        let data = res.data.results;

        let ids = data.map(item => item.user.id);

        if (user && this.id != null) {
            this.loadPlan();
          }

        this.setState({
          isLoading: false,
          patients: data,
          next: nextPageNumber,
          totalPages: roundedPage,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load patient data.', 'error');
      });
  }
  loadTagData() {
    HttpRequest.getallTag()
      .then(res => {
        let data = res?.data?.data;
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }
  handleTagsChange = (event, value) => {
    this.setState({
      selectedTags: value.map(option => option.name),
    });
  };

  save() {
    let user = store.getState().loginReducer.user;

    let {title, description, type, selectedTags, assign, selectedPatients} = this.state;

    if (!title || !description || !type || selectedTags.length === 0) {
      Swal.fire('Error', 'Please fill in all fields.', 'error');
      return;
    }

    // this.setState({ isLoading: true });

    let data = {
      is_removed: false,
      title: title,
      type: type,
      description: description,
      is_active: true,
      tags: selectedTags,
      patients: selectedPatients
      // assign: assign
    };

    HttpRequest.savePlan(this.id, data)
      .then(res => {
        this.setState({isLoading: false});
        Swal.fire('Information', 'Plan saved.', 'success').then(res => {
          // window.location = '/plan';
          this.props.history.push('/plan');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire(
          'Error',
          HttpResponse.processError(err.response, 'Cannot save resource data.'),
          'error',
        );
      });
  }

  handlePatientSelect = (value) => {
    this.setState({loadedPatients: value});
    const assigned = this.state.patients.filter((item) => item.user.id);
    // this.setState({selectedPatients: assigned});
    // handleChange(assigned);
    // handlePatientSelect = (value) => {
        const selectedPatientIds = value.map(patient => patient.user.id);
        this.setState({ selectedPatients: selectedPatientIds });
        console.log("Patients", selectedPatientIds);
    //   }      
}

  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/plan" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to plan management
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
              e.preventDefault();
              this.save();
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <Link
            to="/plan"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Name</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  onChange={event => {
                    this.setState({title: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Plan Type</label>
              <div className="col-sm-3">
                <select
                  className="form-control"
                  value={this.state.type}
                  onChange={event => {
                    this.setState({type: event.target.value});
                  }}>
                  {this.state.planTypes.map((item, key) => {
                    return (
                      <option key={key} value={item.id}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Tags (Separate by Comma)
              </label>
              <div className="col-sm-10">
                <LimitTagAutoComplete
                  tagData={this.state.tagdata}
                  value={this.state.selectedTags}
                  handleTagsChange={this.handleTagsChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Patients
              </label>
              <div className="col-sm-10">
                <ReactPatientSelect
                  options={this.state.patients}
                  handleChange={this.handlePatientSelect}
                  value ={this.state.loadedPatients}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-12">
                <RichTextEditor
                  defaultEditorState={this.state.description}
                  onEditorStateChange={description => {
                    this.setState({description: description});
                  }}
                />
              </div>
            </div>
          </form>
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(PlanEdit);
