import React, {useEffect, useState, useCallback, useMemo} from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import ActivityIndicator from '../components/ActivityIndicator';
import Chat from '../components/Chat';
import CircleImage from '../components/CircleImage';
import Panel from '../components/Panel';
import PanelTab from '../components/PanelTab';
import {HttpRequest} from '../utils/http';
import NoData from '../components/NoData';
import CacheUtils from '../utils/CacheUtils';
import {usePubNub} from 'pubnub-react';
import {useDispatch, useSelector} from 'react-redux';
import PushNotificationUtils from '@utils/PushNotificationUtils';
import MessagingNote from '@components/MessagingNote';
import IconImg from './favicon.png';
import FlexibleInputGroup from '@components/FlexibleInputGroup';
import MilestoneList from '@components/MilstoneList';
import ImagePanel from '@components/ImagePanel';
import {withRouter} from 'react-router-dom';

const Messaging = props => {
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const user = useSelector(state => state.loginReducer.user);
  const allPatients = useSelector(state => state.profiles);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTabMessage, setSelectedTabMessage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [channelIds, setChannelIds] = useState([]);
  const [patients, setPatients] = useState([]);
  const [channels, setChannels] = useState([]);
  const [acceptedChannels, setAcceptedChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [channelMetaData, setChannelMetaData] = useState(null);
  const [isLoadingMilestone, setIsLoadingMilestone] = useState(false);
  const [userLogs, setUserLogs] = useState([]);
  const [assignedProviders, setAssignedProviders] = useState([]);

  const [dateFilter, setDateFilter] = useState('Any');
  const [typeFilter, setTypeFilter] = useState('Any');
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [imageResource, setImageResource] = useState(null);
  const [pubnubMediaUrl, setPubnubMediaUrl] = useState(null);
  const [messageType, setMessageType] = useState('text');

  const [user2Patient, setUser2Patient] = useState({});
  useEffect(() => {
    loadAllPatient();

    document.getElementsByTagName('iframe')[0]?.remove();
    const hash = window.location.hash;
    console.log('🚀 ~ useEffect ~ hash:', hash);

    if (hash !== '') {
      const newRoomID = hash.substring(1);
      const user_id1 = parseInt(newRoomID.split('-')[1]);
      const user_id = parseInt(newRoomID.split('-')[2]);
      HttpRequest.getProfile(user_id1)
        .then(res => {
          setSelectedChannel({
            userId: user_id1,
            patient: res.data.results[0],
            room_id: `private.${user_id1}-${user_id}`,
          });
        })
        .catch(err => {
          console.log('🚀 ~ HttpRequest.getProfile ~ err:', err);
        });
    } else {
      const {id} = props.match.params;

      if (id) {
        initiateChatWithUser(id);
      }
    }
  }, []);

  const initiateChatWithUser = useCallback(
    userId => {
      HttpRequest.getProfile(userId)
        .then(res => {
          setSelectedChannel({
            userId: userId,
            patient: res.data.results[0],
            room_id: `private.${userId}-${user.user.id}`,
          });
        })
        .catch(err => {
          console.log('🚀 ~ HttpRequest.getProfile ~ err:', err);
        });
    },
    [allPatients, setSelectedChannel, user],
  );

  useEffect(() => {
    getMembership();
  }, [user2Patient]);

  //   useEffect(() => {}, [allPatients]);

  useEffect(() => {
    const listener = {message: handleMessage};
    if (selectedChannel) {
      pubnub.addListener(listener);
      pubnub.subscribe({channels: [selectedChannel.room_id]});

      if (selectedChannel.room_id) {
        fetchMessage();
        fetchChannelMetadata();
      }
    }

    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribeAll();
    };
  }, [pubnub, selectedChannel]);

  const loadAllPatient = useCallback(() => {
    HttpRequest.patientList(null)
      .then(res => {
        let patients = res.data.results;
        setPatients(patients);
        let object = {};
        patients.forEach(patient => {
          object[patient.user.id + ''] = patient.id + '';
        });
        setUser2Patient(object);
      })
      .catch(err => {});
  }, []);

  const getMembership = useCallback(
    (showLoading = true) => {
      if (showLoading) {
        setIsLoading(true);
      }
      let hash = window.location.hash;
      let user_id;
      let uuid;
      if (hash != '') {
        let newRoomID = hash.substring(1);
        user_id = parseInt(newRoomID.split('-')[2]);
        uuid = user_id.toString();
      } else {
        uuid = user.user.id + '';
      }
      pubnub.objects
        .getMemberships({
          uuid: uuid,
          include: {
            totalCount: true,
            customFields: true,
            channelFields: true,
            customChannelFields: true,
          },
          sort: {'channel.updated': 'desc'},
        })
        .then(res => {
          let channelIds = ['chat-notification.' + user.user.id];

          let pendingList = [];
          let acceptedList = [];

          let result = res.data.map(message => {
            let {member_1, member_2} = message.channel.custom;
            if (user2Patient[member_1] != null) {
              CacheUtils.findPatient(user2Patient[member_1], dispatch);
            }
            if (user2Patient[member_2] != null) {
              CacheUtils.findPatient(user2Patient[member_2], dispatch);
            }

            message.other_profile =
              user.user.id + '' == member_1 ? member_2 : member_1;

            channelIds.push(message.channel.id);

            if (message.channel.custom.approve) {
              acceptedList.push(message);
            } else {
              pendingList.push(message);
            }
            return message;
          });

          setChannels(pendingList);
          setAcceptedChannels(acceptedList);
          setChannelIds(channelIds);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    },
    [pubnub, user, user2Patient],
  );

  function getUserLogs(userId) {
    if (userId === undefined) {
      return;
    }
    HttpRequest.getActivityLog(userId)
      .then(res => {
        const filteredLogs = res.data.results.filter(log => {
          const logDate = new Date(log.action_time);
          const currentDate = new Date();
          const timeDifference = currentDate - logDate;

          // Filter by date
          if (dateFilter === '1Day' && timeDifference > 24 * 60 * 60 * 1000) {
            return false;
          } else if (
            dateFilter === '1Week' &&
            timeDifference > 7 * 24 * 60 * 60 * 1000
          ) {
            return false;
          } else if (
            dateFilter === '1Month' &&
            timeDifference > 30 * 24 * 60 * 60 * 1000
          ) {
            return false;
          }

          // Filter by type
          if (typeFilter !== 'Any' && log.action_flag_desc !== typeFilter) {
            return false;
          }

          return true;
        });

        const userLogs = filteredLogs.map((log, key) => {
          return {
            title: log.action_flag_desc,
            type: log.content_type_desc,
            created: log.action_time,
            description: log.change_message_json,
          };
        });

        setUserLogs(userLogs);
      })
      .catch(err => {
        // setIsLoading(false);
        Swal.fire('Error', 'Cannot load user log data.', 'error');
      });
  }

  function loadAssignedProviders(patientID) {
    if (patientID === undefined || null) {
      return;
    }

    HttpRequest.getPatientDetail(patientID)
      .then(res => {
        // HttpRequest.assignedProvider(patientID)
        HttpRequest.assignedProvider(res?.data?.results[0]?.id)
          .then(res => {
            setAssignedProviders(res?.data?.detail?.providers);
          })
          .catch(err => {
            Swal.fire('Error', 'Cannot Load Assigned Providers', 'error');
          });
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot Load Assigned Providers', 'error');
      });
  }
  useEffect(() => {
    if (selectedChannel) {
      getUserLogs(selectedChannel.userId);
      loadAssignedProviders(selectedChannel.userId);
    }
  }, [selectedChannel, dateFilter, typeFilter]);

  const fetchMessage = useCallback(() => {
    if (selectedChannel?.room_id) {
      setMessages([]);
      pubnub.fetchMessages(
        {
          channels: [selectedChannel?.room_id],
          count: 100,
        },
        (status, response) => {
          if (response) {
            let messages = response.channels[selectedChannel?.room_id];
            if (messages) {
              setMessages(messages);
            }
          }

          setIsLoadingChat(false);
        },
      );
    }
  }, [pubnub, selectedChannel]);

  const fetchChannelMetadata = useCallback(() => {
    pubnub.objects
      .getChannelMetadata({
        channel: selectedChannel?.room_id,
      })
      .then(res => {
        setChannelMetaData(res.data.custom);
      })
      .catch(err => {});
  }, [selectedChannel, pubnub]);

  const handleMessage = useCallback(
    event => {
      fetchMessage();
    },
    [fetchMessage],
  );

  const transformMessage = useCallback(
    msg => {
      let publisherID = msg.publisher;
      if (publisherID == null) {
        publisherID = msg.uuid;
      }

      let obj = {
        _id: msg.timetoken,
        createdAt: moment(parseInt(msg.timetoken) / 10000).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        user: {
          _id: publisherID,
          name: 'N/A',
          avatar: null, //user?.user?.id == publisherID ? imageUtil.getRawImage(user?.image) : imageUtil.getRawImage(null),
        },
      };

      let messageContent = msg.message;

      try {
        if (messageContent.text != null) {
          obj.text = messageContent.text;
        } else if (messageContent.image != null) {
          obj.image = messageContent.image;
        } else if (messageContent.video != null) {
          obj.video = messageContent.video;
        } else if (messageContent.audio != null) {
          obj.audio = messageContent.audio;
        } else {
          return null;
        }
      } catch (err) {
        return null;
      }

      return obj;
    },
    [user],
  );

  const uploadFile = useCallback(
    resource => {
      let obj = {
        file: resource,
        pubnub,
        channel: selectedChannel?.room_id,
      };

      if (resource.frames) {
        const gifUrl = resource.url;
        fetch(gifUrl)
          .then(response => response.blob()) // Convert the response to a Blob object
          .then(blob => {
            const gifFile = new File([blob], 'my_gif.gif', {type: 'image/gif'});
            uploadFile(gifFile)
              .then(res => {
                setPubnubMediaUrl(res);
                setIsUploadingMedia(false);
                sendMessage(res, 'gif'); // send the message as a GIF
              })
              .catch(err => {
                // Handle the error
                setIsUploadingMedia(false);
              });
          })
          .catch(error => {});
      } else {
        let type = 'image';
        if (resource.type == 'video/mp4') {
          type = 'video';
        } else if (resource.type == 'audio/wav') {
          type = 'audio';
        }

        setMessageType(type);
        setIsUploadingMedia(true);

        PushNotificationUtils.uploadFile(obj)
          .then(res => {
            setPubnubMediaUrl(res);
            setIsUploadingMedia(false);
            sendMessage(res, 'image');
          })
          .catch(err => {
            // Toast.showError('Cannot upload media, please try again');
            setImageResource(null);
            setIsUploadingMedia(false);
          });
      }
    },
    [pubnub, selectedChannel],
  );

  const sendMessage = useCallback(
    (message, messageType = 'text') => {
      const obj = {
        notificationTitle: user.user.name,
        notificationText: message,
        channel: selectedChannel?.room_id,
        type: messageType, // image, video, audio
        content: message,
        channelMetaData: channelMetaData,
        pubnub: pubnub,
        approve: true,
        callback: () => {
          if (selectedTab === 0) {
            setSelectedTab(1);
          }
          getMembership(false);
        },
      };

      if (messageType !== 'text') {
        obj.notificationText = '(File)';
      }

      PushNotificationUtils.sendMessage(obj);

      setImageResource(null);
      setPubnubMediaUrl(null);
      setMessageType('text');
    },
    [user, selectedChannel, pubnub, channelMetaData, getMembership],
  );

  const shownChannel = useMemo(() => {
    return selectedTab == 0 ? channels : acceptedChannels;
  }, [selectedTab, channels, acceptedChannels]);

  console.log('"Selected', messages);

  return (
    <div className="row">
      <div
        className={
          selectedChannel != null ? 'col-md-1 hide-scrollbar-thin' : 'col-md-12'
        }>
        {selectedChannel === null && (
          <PanelTab
            tabs={[
              'Chat Request' +
                (channels.length != 0 ? ' (' + channels.length + ')' : ''),
              'Conversation' +
                (acceptedChannels.length != 0
                  ? ' (' + acceptedChannels.length + ')'
                  : ''),
            ]}
            selectedIndex={selectedTab}
            onChange={selectedIndex => {
              setSelectedTab(selectedIndex);
            }}
          />
        )}

        <Panel
          style={{
            padding: selectedChannel !== null ? '0' : '16px',
            background: selectedChannel !== null ? 'none' : 'white',
          }}>
          {isLoading && <ActivityIndicator />}

          {!isLoading && (
            <>
              {shownChannel.length == 0 && (
                <>
                  <NoData>No data available</NoData>
                </>
              )}

              {shownChannel.map((item, key) => {
                let boldStyle = null;
                if (item.unreadCount > 0) {
                  boldStyle = {fontWeight: 'bold'};
                }

                let patient = allPatients[item.other_profile + ''];
                let fullName = '';
                if (patient) {
                  fullName =
                    patient?.user?.name + ' ' + patient?.user?.last_name;
                } else if (item.channel.custom.name) {
                  fullName = item.channel.custom.name + ' (Guest)';
                }

                if (fullName.trim() == 'null' || fullName.trim() == '') {
                  fullName = 'Guest # ' + item.channel.id + '';
                }

                // let lastMessage = (
                //   <Text style={{fontStyle: 'italic'}}>(No Message)</Text>
                // );
                // if (item.channel.custom.lastMessage) {
                //   lastMessage = item.channel.custom.lastMessage;
                // }

                let selectedList = {};
                if (selectedChannel?.room_id == item.channel.id) {
                  selectedList = styles.listSelected;
                }

                return (
                  <div
                    key={key}
                    style={{
                      ...styles.list,
                      border:
                        selectedChannel === null ? '1px solid #eeeeee' : 'none',
                    }}
                    onClick={() => {
                      // props.navigation.navigate("ProviderSingleMessaging", {
                      // 	customName: item.channel.custom.name,
                      // 	patient: patient,
                      // 	room_id: item.channel.id,
                      // 	room_data: item
                      // });
                      if (selectedChannel?.room_id == item.channel.id) {
                        setSelectedChannel(null);
                      } else {
                        setSelectedChannel({
                          userId: item.other_profile,
                          customName: item.channel.custom.name,
                          patient: patient,
                          room_id: item.channel.id,
                          room_data: item,
                        });
                        setSelectedTabMessage(0);
                      }
                    }}>
                    <CircleImage
                      src={patient?.user.image ? patient?.user.image : IconImg}
                      style={{...selectedList}}
                      size={60}
                    />
                    {selectedChannel === null && (
                      <div style={styles.listContent}>
                        <div style={{...styles.doctorName, ...boldStyle}}>
                          {fullName}, ID: {item.other_profile}
                        </div>
                        <div style={{...styles.message, boldStyle}}>
                          {/* {lastMessage} */}
                        </div>
                        <div style={{...styles.time, boldStyle}}>
                          {moment(item.updated).format('MMM DD, HH:mm')}
                        </div>
                      </div>
                    )}

                    {item.unreadCount > 0 && (
                      <div style={styles.unread}>{item.unreadCount}</div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </Panel>
      </div>

      {selectedChannel != null && (
        <div className="col-md-7">
          <PanelTab
            tabs={
              selectedChannel.patient != null
                ? ['Messages', 'Notes']
                : ['Messages']
            }
            selectedIndex={selectedTabMessage}
            onChange={selectedIndex => {
              setSelectedTabMessage(selectedIndex);
            }}
          />

          <Panel>
            {isLoadingChat && <ActivityIndicator />}

            {!isLoadingChat && (
              <>
                {selectedTabMessage == 0 && (
                  <>
                    <Chat
                      isUploading={isUploadingMedia}
                      messages={messages}
                      userId={user.user.id + ''}
                      selectedUserId={selectedChannel.userId}
                      // userName={user.user.name}
                      user={selectedChannel.patient}
                      onSend={(msg, is_file) => {
                        if (!is_file) {
                          sendMessage(msg);
                        } else {
                          uploadFile(msg);
                        }
                        //this.sendMessage(msg, is_file);
                      }}
                    />
                  </>
                )}

                {selectedTabMessage == 1 && (
                  <MessagingNote userId={selectedChannel.userId} 
                  user={selectedChannel.patient} />
                )}
              </>
            )}
          </Panel>
        </div>
      )}
      {selectedChannel != null && (
        <>
          <div className="col-md-4">
            <Panel style={{height: '70vh', overflow: 'auto'}}>
              <div className="section-title">Recent activity</div>
              <div className="row" style={{marginBottom: 16}}>
                <div className="col-md-6">
                  <FlexibleInputGroup title="Date">
                    <select
                      className="grid"
                      value={dateFilter}
                      onChange={event => setDateFilter(event.target.value)}>
                      <option value="Any">Any</option>
                      <option value="1Day">Less than 1 day</option>
                      <option value="1Week">Less than 1 week</option>
                      <option value="1Month">Less than 1 month</option>
                    </select>
                  </FlexibleInputGroup>
                </div>
                <div className="col-md-6">
                  <FlexibleInputGroup title="Type">
                    <select
                      className="grid"
                      value={typeFilter}
                      onChange={event => setTypeFilter(event.target.value)}>
                      <option value="Any">Any</option>
                      <option value="Addition">Addition</option>
                      <option value="Change">Change</option>
                      <option value="Deletion">Deletion</option>
                    </select>
                  </FlexibleInputGroup>
                </div>
              </div>
              {isLoadingMilestone && <ActivityIndicator />}
              <MilestoneList data={userLogs} />
            </Panel>
            <div className="section-title">
              All assigned providers ({assignedProviders.length})
            </div>
            {assignedProviders.map(data => (
              <ImagePanel
                imgSource={data.image || IconImg}
                title={data.name + ' ' + data.last_name}
                description="YBGRConnect Specialist"
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Messaging);

const styles = {
  list: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 16px 16px 16px',
    marginTop: 16,
    alignItems: 'center',
    height: 80,
    // border: '1px solid #eeeeee',
    borderRadius: 10,
    cursor: 'pointer',
  },
  listSelected: {
    border: '4px solid var(--color-primary)',
  },
  listContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginLeft: 15,
    cursor: 'pointer',
  },
  doctorName: {
    fontSize: 18,
    fontWeight: '400',
  },
  message: {
    fontSize: 12,
  },
  time: {
    fontSize: 12,
  },
  unread: {
    position: 'absolute',
    top: 0,
    left: 16,
    backgroundColor: '#8FD7FF',
    width: 26,
    height: 26,
    borderRadius: 13,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: 'blue',
    borderRadius: 25,
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
};
