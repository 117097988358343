import React from 'react';
import {withRouter} from 'react-router';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  isBefore,
  subDays,
  subWeeks,
  subMonths,
  isWithinInterval,
} from 'date-fns';
import CircleImage from '../components/CircleImage';
import DataGrid from '../components/DataGrid';
import HorizontalLine from '../components/HorizontalLine';
import ImagePanel from '../components/ImagePanel';
import Panel from '../components/Panel';
import PanelTab from '../components/PanelTab';
import TextInput from '../components/TextInput';
import FlexibleInputGroup from '../components/FlexibleInputGroup';
import MilestoneList from '../components/MilstoneList';
import {Link} from 'react-router-dom';
import {HttpRequest} from '../utils/http';
import {store} from '../store';
import ActivityIndicator from '../components/ActivityIndicator';

import Swal from 'sweetalert2';
import StringHelper from '../utils/StringHelper';
import Mime from 'mime-types';
import fcmRoom from '../utils/fcmRoom';

import ProfilePic from '../assets/logo192.png';
import NoData from '@components/NoData';

class PatientDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingProfile: false,
      isLoadingMilestone: false,

      name: '',
      last_name: '',
      title: '',
      department: '',
      phone: '',
      email: '',
      gender: '',
      image: null,
      patientId: null,
      last_activity: '',
      chatList: [],
      selectedRoom: '',
      selectedIndex: 0,
      searchText: '',
      selectedDate: '',
      selectedDateFilter: '',
      selectedDateFilterLogs: '',
      selectedUrgency: '',
      milestones: [],
      notes: [],
      provider_id: null,
      providers: [],
      assignedProviders: [],
      tags: [],
      selectedChats: null,
      dateFilter: 'Any',
      typeFilter: 'Any',
      filteredMilestones: [],
    };

    this.id = props.match.params.id;
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadProfile();
      // this.loadMilestone();
      this.loadAssignedProvider();
    //   this.loadChatRoom();
    //   this.loadNotes();
      this.loadProvider();
    }
  }

  loadProfile() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoadingProfile: true});
    HttpRequest.getPatient(this.id)
      .then(res => {
        let data = res.data.data;

        this.setState({
          isLoadingProfile: false,
          name: data.user.name,
          last_name: data.user.last_name,
          patientId: data.user.id,
          image: data.user.image,
          tags: data.tags,
          last_activity: data.last_activity,
          // assignedProviders: data.providers
        });
        this.loadMilestone(data.user.id);
        this.loadNotes(data?.user?.id);
        this.loadChatRoom(data?.user?.id);
        HttpRequest.getProfile(data.user.id)
          .then(res => {
            let profile = res.data.results[0];
            this.setState({
              phone: profile.phone,
              gender: profile.gender,
              email: profile.user.email,
            });
          })
          .catch(err => {
            this.setState({isLoadingProfile: false});
            Swal.fire('Error', 'Cannot load Profile Data', 'error');
          });
      })
      .catch(err => {
        this.setState({isLoadingProfile: false});
        Swal.fire('Error', 'Cannot load patient data. Detial', 'error');
      });
  }

  loadChatRoom(patient_id) {
    let user = store.getState().loginReducer.user;
    HttpRequest.roomList(null, patient_id, null)
      .then(res => {
        let chatList = res.data.results.map(item => {
          let lastMessage = '(No Message)';
          if (item.last_message) {
            lastMessage = item.last_message;
            if (StringHelper.isValidURL(lastMessage)) {
              let mimeFile =
                Mime.lookup(StringHelper.pathBaseName(lastMessage)) ||
                'image/jpeg';
              let start = mimeFile.substr(0, 5);
              if (start == 'image') {
                lastMessage = (
                  <img
                    src={item.last_message}
                    alt="last_message"
                    width="40px"
                    height="40px"
                  />
                );
              } else if (start == 'video') {
                lastMessage = ':movie_camera: (Video)';
              } else if (start == 'audio') {
                lastMessage = ':musical_note: (Audio)';
              }
            }
          }
          return {
            id: item.id,
            room: item.room,
            sender: item.sender,
            receiver: item.receiver,
            last_message: lastMessage, // Fixed the error here
            modified: moment(item.modified).format('YYYY-MM-DD HH:mm:ss'),
          };
        });

        this.setState({isLoadingChatRequest: false, chatList});
      })
      .catch(err => {
        this.setState({isLoadingChatRequest: false});
        Swal.fire('Error', 'Cannot load chat message.', 'error');
      });
  }

  loadMilestone(id) {
    let user = store.getState().loginReducer.user;

    this.setState({isLoadingMilestone: true});

    HttpRequest.getActivityLog(id)
      .then(res => {
        let data = res.data.results;

        const userLogs = res.data.results.map((log, key) => {
          return {
            title: log.action_flag_desc,
            type: log.content_type_desc,
            created: log.action_time,
            description: log.change_message_json,
          };
        });

        // Initialize filteredMilestones with all milestones
        this.setState({
          isLoadingMilestone: false,
          milestones: userLogs,
          filteredMilestones: userLogs, // Initialize filteredMilestones with all milestones
        });
      })
      .catch(err => {
        this.setState({isLoadingMilestone: false});
        Swal.fire('Error', 'Cannot load milestone data.', 'error');
      });
  }

  filterMilestones() {
    const {dateFilter, typeFilter, milestones} = this.state;

    const filteredMilestones = milestones.filter(milestone => {
      // Parse milestone date as a Date object
      const milestoneDate = new Date(milestone.created);
      const currentDate = new Date();

      let dateFilterCondition = true;
      if (dateFilter === 'lessThan1Day') {
        dateFilterCondition = isWithinInterval(milestoneDate, {
          start: new Date(currentDate.getTime() - 24 * 60 * 60 * 1000),
          end: currentDate,
        });
      } else if (dateFilter === 'lessThan1Week') {
        dateFilterCondition = isWithinInterval(milestoneDate, {
          start: new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000),
          end: currentDate,
        });
      } else if (dateFilter === 'lessThan1Month') {
        dateFilterCondition = isWithinInterval(milestoneDate, {
          start: new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000),
          end: currentDate,
        });
      }

      let typeFilterCondition = true;
      if (typeFilter !== 'Any') {
        typeFilterCondition = milestone.title === typeFilter;
      }

      return dateFilterCondition && typeFilterCondition;
    });

    this.setState({filteredMilestones});
  }
  handleDateFilterChange = event => {
    const dateFilter = event.target.value;
    this.setState({dateFilter}, () => {
      this.filterMilestones();
    });
  };

  handleTypeFilterChange = event => {
    const typeFilter = event.target.value;
    this.setState({typeFilter}, () => {
      this.filterMilestones();
    });
  };

  loadAssignedProvider() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoadingProvider: true});

    HttpRequest.patientProvidersList(this.id)
      .then(res => {
        let data = res.data.detail.providers;

        this.setState({
          isLoadingProvider: false,
          assignedProviders: data,
        });
      })
      .catch(err => {
        this.setState({isLoadingProvider: false});
        Swal.fire('Error', 'Cannot load provider list. fvvrvv', 'error');
      });
  }

  deleteAssignedPatient(id) {
    if (
      window.confirm('Are you sure want to delete this assigned provider ?')
    ) {
      let data = {
        providers: [id],
      };
      HttpRequest.deleteAssignedProvider(this.id, data)
        .then(res => {
          Swal.fire(
            'Information',
            'Successfully remove assigned provider.',
            'success',
          );
          this.loadAssignedProvider();
        })
        .catch(err => {
          this.setState({isLoading: false});
          Swal.fire('Error', 'Cannot delete data.', 'error');
        });
    }
  }
  applyChatFilters() {
    const {searchText, selectedDateFilter, selectedUrgency, chatList} =
      this.state;

    const filteredChats = chatList.filter(item => {
      // Filter by search text
      if (searchText) {
        const senderName = item.sender.name || '';
        if (!senderName.toLowerCase().includes(searchText.toLowerCase())) {
          return false;
        }
      }

      // Filter by date
      if (selectedDateFilter) {
        const itemDate = new Date(item.modified);

        if (selectedDateFilter === '<24h') {
          const twentyFourHoursAgo = subDays(new Date(), 1);
          if (isBefore(itemDate, twentyFourHoursAgo)) {
            return false;
          }
        } else if (selectedDateFilter === '<1w') {
          const oneWeekAgo = subWeeks(new Date(), 1);
          if (isBefore(itemDate, oneWeekAgo)) {
            return false;
          }
        } else if (selectedDateFilter === '<1m') {
          const oneMonthAgo = subMonths(new Date(), 1);
          if (isBefore(itemDate, oneMonthAgo)) {
            return false;
          }
        }
      }

      // Filter by urgency
      if (selectedUrgency) {
        if (selectedUrgency === 'High' && item.urgency !== 'High') {
          return false;
        } else if (selectedUrgency === 'Low' && item.urgency !== 'Low') {
          return false;
        }
      }

      return true;
    });

    this.setState({selectedChats: filteredChats});
  }

  loadNotes(id) {
    this.setState({isLoadingNotes: true});

    HttpRequest.noteList(id)
      .then(res => {
        let data = res.data.results;

        this.setState({
          isLoadingNotes: false,
          notes: data,
        });
      })
      .catch(err => {
        this.setState({isLoadingNotes: false});
        Swal.fire('Error', 'Cannot load note data.', 'error');
      });
  }

  loadProvider() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoadingProvider: true});

    HttpRequest.providerList()
      .then(res => {
        let data = res.data.results;

        this.setState({
          isLoadingProvider: false,
          providers: data,
        });
      })
      .catch(err => {
        this.setState({isLoadingProvider: false});
        Swal.fire('Error', 'Cannot load provider list. fvvrvv', 'error');
      });
  }

  assignProvider() {
    window.jQuery('#myModal').modal('hide');

    this.setState({isLoadingAssign: true});

    let data = {
      providers: [parseInt(this.state.provider_id)],
    };
    HttpRequest.saveAssignedProvider(this.id, data)
      .then(res => {
        let data = res.data.results;
        Swal.fire('Information', 'Assign a provider success.', 'success');
        this.setState({provider_id: null});
        this.loadAssignedProvider();
      })
      .catch(err => {
        this.setState({isLoadingProvider: false});
        Swal.fire('Error', 'Cannot assign a provider.', 'error');
        this.setState({provider_id: null});
      });
  }

  deleteTag(tag) {
    let tags = this.state.tags;
    let newTags = [];

    tags.map(item => {
      if (item != tag) {
        newTags.push(item);
      }
    });

    this.setState({tags: newTags}, () => {
      HttpRequest.savePatchPatient(this.id, {tags: newTags})
        .then(res => {
          Swal.fire('Information', 'Remove tag success.', 'success');
          this.loadProfile();
        })
        .catch(err => {
          Swal.fire('Error', 'Cannot remove tag.', 'error');
          this.loadProfile();
        });
    });
  }

  chatAsProvider() {
    window.jQuery('#chatModal').modal('hide');
    let user = store.getState().loginReducer.user;

    let room = this.state.selectedRoom;
    if (room != '') {
      this.props.history.push(
        user.user.role === 1
          ? '/admin-messaging/messaging/#' + room
          : '/messaging/#' + room,
      );
      // window.location = user.user.role === 1 ? '/admin-messaging/messaging/#' + room : '/messaging/#' + room
    } else {
      Swal.fire('Error', 'Please choose provider.', 'error');
    }
  }

  render() {
    let user = store.getState().loginReducer.user;
    const formattedLastActivity = moment(this.state.last_activity).format(
      'MMM D YYYY - h:mm a',
    );

    return (
      <div className="row">
        <div className="col-md-8">
          {this.state.isLoadingProfile && <ActivityIndicator />}

          {!this.state.isLoadingProfile && (
            <>
              <div className="flex-row" style={{marginBottom: 30}}>
                <CircleImage
                  src={this.state.image ? this.state.image : ProfilePic}
                  size={200}
                  maxSize={'30vw'}
                />

                <div
                  className="flex-column flex justify-center"
                  style={{marginLeft: 32}}>
                  <h3>
                    {this.state.name} {this.state.last_name}{' '}
                    <small style={styles.pronoun}>
                      Preferred Pronoun: {this.state.gender}
                    </small>
                  </h3>
                  <div className="flex-row" style={{marginBottom: 16}}>
                    {this.state.tags.map((tag, key) => {
                      return (
                        <span className="tag" key={key}>
                          {tag}

                          <a
                            href="#"
                            className="btn-delete"
                            onClick={() => this.deleteTag(tag)}>
                            x
                          </a>
                        </span>
                      );
                    })}
                  </div>
                  <div className="flex-row">
                    <Link
                      to={'/patient/edit/' + this.state.patientId}
                      className="btn btn-primary"
                      style={{marginRight: 10}}>
                      Edit Profile
                    </Link>
                    <Link
                      to={user.user.role === 1 ? `/admin-messaging/messaging/${this.state.patientId}` : `/messaging/${this.state.patientId}`}
                      className="btn btn-warning"
                      >
                      Send message
                    </Link>
                  </div>
                </div>
              </div>

              <div className="section-title">
                All assigned providers ({this.state.assignedProviders.length})
              </div>

              <div className="row" style={{marginBottom: 16}}>
                {this.state.assignedProviders.map((assignedProvider, key) => {
                  return (
                    <div className="col-sm-6 col-md-4" key={key}>
                      {user.user.role === 1 ? (
                        <ImagePanel
                          imgSource={assignedProvider.image || ProfilePic}
                          title={
                            assignedProvider.name +
                            ' ' +
                            assignedProvider.last_name
                          }
                          description="YBGRConnect Specialist"
                          actions={[
                            {
                              label: 'Delete Provider',
                              onClick: () => {
                                this.deleteAssignedPatient(assignedProvider.id);
                              },
                            },
                          ]}
                        />
                      ) : (
                        <ImagePanel
                          imgSource={assignedProvider.image || ProfilePic}
                          title={
                            assignedProvider.name +
                            ' ' +
                            assignedProvider.last_name
                          }
                          description="YBGRConnect Specialist"
                        />
                      )}
                    </div>
                  );
                })}
                {this.state.assignedProviders.length < 3 &&
                  user.user.role === 1 && (
                    <div
                      className="col-sm-6 col-md-4"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        window.jQuery('#myModal').modal('show');
                      }}>
                      <ImagePanel
                        imgSource={ProfilePic}
                        title="Assign a specialist"
                        description="Add YBGRConnect Specialist"
                      />
                    </div>
                  )}
              </div>

              <Panel style={{paddingTop: 0}}>
                <HorizontalLine title="Personal Information" />

                <div className="row" style={{paddingTop: 10}}>
                  <div className="col-md-6">
                    <TextInput
                      value={this.state.name}
                      title="Name"
                      placeholder="Enter your name"
                      onChange={event => {
                        this.setState({name: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      value={this.state.last_name}
                      title="Last Name"
                      placeholder="Enter your last name"
                      onChange={event => {
                        this.setState({last_name: event.target.value});
                      }}
                    />
                  </div>
                </div>

                <HorizontalLine title="Professional Information" />

                <div className="row" style={{paddingTop: 10}}>
                  <div className="col-md-6">
                    <TextInput
                      value={this.state.title}
                      title="Title"
                      placeholder="Enter your title"
                      onChange={event => {
                        this.setState({title: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      value={this.state.department}
                      title="Department"
                      placeholder="Enter your department"
                      onChange={event => {
                        this.setState({department: event.target.value});
                      }}
                    />
                  </div>
                </div>

                <HorizontalLine title="Contact Information" />

                <div className="row" style={{paddingTop: 10}}>
                  <div className="col-md-6">
                    <TextInput
                      value={this.state.phone}
                      title="Phone number"
                      placeholder="Enter your phone number"
                      onChange={event => {
                        this.setState({phone: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      value={this.state.email}
                      title="Email"
                      placeholder="Enter your email"
                      onChange={event => {
                        this.setState({email: event.target.value});
                      }}
                    />
                  </div>
                </div>
              </Panel>

              <PanelTab
                tabs={['Messages', 'Notes', 'Tags']}
                selectedIndex={this.state.selectedIndex}
                onChange={selectedIndex => {
                  this.setState({selectedIndex});
                }}
              />

              {this.state.selectedIndex == 0 && (
                <Panel>
                  <div className="section-title">All message</div>
                  <DataGrid
                    filters={[
                      {
                        type: 'textinput',
                        label: 'Search contacts',
                        icon: faSearch,
                        onChange: text => {
                          this.setState(
                            {searchText: text},
                            this.applyChatFilters,
                          );
                        },
                        className: 'col-sm-12',
                        style: {flex: 1},
                      },

                      {
                        type: 'select',
                        label: 'Date',
                        data: [
                          {id: '', label: 'Any'},
                          {id: '<24h', label: '<24 hours'},
                          {id: '<1w', label: '<1 week'},
                          {id: '<1m', label: '<1 month'}, // New option
                        ],
                        onChange: text => {
                          this.setState(
                            {selectedDateFilter: text},
                            this.applyChatFilters,
                          );
                        },
                        className: 'col-md-4 col-sm-6',
                      },
                      {
                        type: 'select',
                        label: 'Urgency',
                        data: [
                          {id: '', label: 'Any'},
                          {id: 'High', label: 'High'},
                          {id: 'Low', label: 'Low'},
                        ],
                        onChange: text => {
                          this.setState(
                            {selectedUrgency: text},
                            this.applyChatFilters,
                          );
                        },
                        className: 'col-md-4 col-sm-6',
                      },
                    ]}
                    columns={[
                      {
                        label: 'Message',
                        renderItem: item => {
                          return item.last_message;
                        },
                      },
                      {
                        label: 'Sent By',
                        renderItem: item => {
                          return (
                            <>
                              <CircleImage
                                src={item.sender.image || ProfilePic}
                                size={30}
                              />
                              <span style={{marginLeft: 10}}>
                                {item.sender?.name}
                              </span>
                            </>
                          );
                        },
                      },
                      {
                        label: 'Answered By',
                        renderItem: item => {
                          return (
                            <>
                              <CircleImage
                                src={item.receiver[0].image || ProfilePic}
                                size={30}
                              />
                              <span style={{marginLeft: 10}}>
                                {item.receiver[0]?.name + " " + item.receiver[0]?.last_name} 
                              </span>
                            </>
                          );
                        },
                      },
                      {
                        label: 'Date',
                        renderItem: item => {
                          return moment(item.modified).fromNow();
                        },
                      },
                    ]}
                    data={
                      this.state.selectedChats
                        ? this.state.selectedChats
                        : this.state.chatList || []
                    }
                  />
                </Panel>
              )}

              {this.state.selectedIndex == 1 && (
                <Panel>
                  <div className="section-title">All notes</div>
                  <DataGrid
                    filters={[]}
                    columns={[
                      {
                        label: 'Notes',
                        renderItem: item => {
                          return item.notes;
                        },
                      },
                      {
                        label: 'Date',
                        renderItem: item => {
                          return moment(item.created).fromNow();
                        },
                      },
                    ]}
                    data={this.state.notes}
                  />
                </Panel>
              )}

              {this.state.selectedIndex == 2 && (
                <Panel>
                  <div className="section-title">All tags</div>
                  <div className="d-flex justify-content-center">
                    {(!this.state.tags || this.state.tags.length === 0) && (
                      <tr>
                        <td>
                          <NoData>No data available</NoData>
                        </td>
                      </tr>
                    )}
                  </div>
                  {this.state.tags.map((tag, key) => {
                    return (
                      <span
                        className="badge badge-success"
                        key={key}
                        style={{marginRight: 5}}>
                        {tag}
                      </span>
                    );
                  })}
                </Panel>
              )}
            </>
          )}
        </div>

        <div className="col-md-4">
          <Panel style={styles.lastLogin}>
            <div className="section-title mb-0">Last Login</div>
            <div className="section-title mb-0">{formattedLastActivity}</div>
          </Panel>
          <Panel style={{height: '80vh', overflow: 'auto'}}>
            <div className="section-title">Recent activity</div>

            <div className="row" style={{marginBottom: 16}}>
              <div className="col-md-6">
                <FlexibleInputGroup title="Date Filter">
                  <select
                    className="grid"
                    value={this.state.dateFilter}
                    onChange={this.handleDateFilterChange}>
                    <option value="">Any</option>
                    <option value="lessThan1Day">Less than 1 day</option>
                    <option value="lessThan1Week">Less than 1 week</option>
                    <option value="lessThan1Month">Less than 1 month</option>
                  </select>
                </FlexibleInputGroup>
              </div>

              <div className="col-md-6">
                <FlexibleInputGroup title="Type">
                  <select
                    className="grid"
                    value={this.state.typeFilter}
                    onChange={this.handleTypeFilterChange}>
                    <option value="Any">Any</option>
                    <option value="Addition">Addition</option>
                    <option value="Change">Change</option>
                    <option value="Deletion">Deletion</option>
                  </select>
                </FlexibleInputGroup>
              </div>
            </div>

            {this.state.isLoadingMilestone && <ActivityIndicator />}

            {!this.state.isLoadingMilestone && (
              <MilestoneList data={this.state.filteredMilestones} />
            )}
          </Panel>
        </div>

        <div className="modal" tabindex="-1" role="dialog" id="myModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Assign a Provider</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Provider</label>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        value={this.state.provider_id || ''}
                        onChange={event => {
                          this.setState({provider_id: event.target.value});
                        }}>
                        <option value="">Choose Provider</option>
                        {this.state.providers.map((user, key) => {
                          return (
                            <option value={user.user.id} key={key}>
                              {user.user.name} {user.user.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.assignProvider();
                  }}>
                  Save changes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" tabindex="-1" role="dialog" id="chatModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Chat As Provider</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Provider</label>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        value={this.state.selectedRoom || ''}
                        onChange={event => {
                          this.setState({selectedRoom: event.target.value});
                        }}>
                        <option value="">Choose Provider</option>
                        {this.state.providers.map((user, key) => {
                          return (
                            <option value={user.user.id} key={key}>
                              {user.user.name} {user.user.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.chatAsProvider();
                  }}>
                  Continue
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setState({selectedRoom: null})}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  pronoun: {
    fontSize: 13,
    marginLeft: 20,
  },
  lastLogin: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default withRouter(PatientDetail);
