import React, {Component} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   editorState: this.convertValueToState(props.value),
    // };
    this.state = {
      editorState: this.props.defaultEditorState
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(this.props.defaultEditorState)),
          )
        : EditorState.createEmpty(),
    };
  }
//   componentDidUpdate(prevProps) {
    // if (prevProps.value !== this.props.value) {
    //   const editorState = this.convertValueToState(this.props.value);
    //   this.setState({editorState});
    // }
//   }

  convertValueToState(value) {
    if (!value) {
      return EditorState.createEmpty();
    }
    const jsonObject = JSON.parse(value);
    const contentState = convertFromRaw(jsonObject);
    return EditorState.createWithContent(contentState);
  }
  handleEditorChange = editorState => {
    this.setState({editorState});
    if (this.props.onEditorStateChange) {
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      this.props.onEditorStateChange(JSON.stringify(rawContentState));
    }
  };

  render() {
    return (
      <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.handleEditorChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    );
  }
}
export default RichTextEditor;
// import React, { Component } from 'react';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// class RichTextEditor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editorState: this.convertValueToState(props.defaultEditorState),
//     };
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps.defaultEditorState !== this.props.defaultEditorState) {
//       const editorState = this.convertValueToState(this.props.defaultEditorState);
//       this.setState({ editorState });
//     }
//   }

//   convertValueToState(value) {
//     if (!value) {
//       return EditorState.createEmpty();
//     }
//     const jsonObject = JSON.parse(value);
//     const contentState = convertFromRaw(jsonObject);
//     return EditorState.createWithContent(contentState);
//   }

//   handleEditorChange = (editorState) => {
//     if (this.props.onEditorStateChange) {
//       const contentState = editorState.getCurrentContent();
//       const rawContentState = convertToRaw(contentState);
//       this.props.onEditorStateChange(JSON.stringify(rawContentState));
//     }
//     this.setState({ editorState });
//   };

//   render() {
//     return (
//       <Editor
//         editorState={this.state.editorState}
//         onEditorStateChange={this.handleEditorChange}
//         // toolbarClassName="toolbarClassName"
//         // wrapperClassName="wrapperClassName"
//         // editorClassName="editorClassName"
//         wrapperClassName="demo-wrapper"
//         editorClassName="demo-editor"

//         localization={{ locale: 'en', translations: { 'generic.add': 'Add' } }}
//       />
//     );
//   }
// }

// export default RichTextEditor;
// import React, { Component } from 'react';
// import { convertFromRaw, EditorState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// class RichTextEditor extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             editorState: this.props.initialContent
//                 ? EditorState.createWithContent(
//                       convertFromRaw(JSON.parse(this.props.initialContent))
//                   )
//                 : EditorState.createEmpty(),
//         };
//     }

//     onEditorStateChange = editorState => {
//         this.setState({
//             editorState,
//         });
//         this.props.onContentStateChange(editorState.getCurrentContent());
//     };

//     render() {
//         return (
//             <Editor
//                 editorState={this.state.editorState}
//                 wrapperClassName="demo-wrapper"
//                 editorClassName="demo-editor"
//                 onEditorStateChange={this.onEditorStateChange}
//             />
//         );
//     }
// }

// export default RichTextEditor;
